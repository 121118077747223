import router from "./router/index.js";

const BASE_URL = window.location.origin.includes("localhost") ? "http://localhost:5000" : window.location.origin.includes("dev") ? "https://dev.vocalapi.co/api" : "https://api.vocalapi.co/api";

let callQueue = [];
export default class PortalAPI {
	constructor(state, baseURL = BASE_URL) {
		this.state = state;
		this.baseURL = baseURL;

		return {
			call: (cls, func, params, autoUpdateData) => {
				return this.call(cls, func, params, autoUpdateData);
			},
			baseURL: this.baseURL
		}
	}

	verifyResponse(response, autoUpdateData = true) {
		if (response.status === 403) {
			window.localStorage.setItem("isLoggedIn", 0);
			this.state.user = null;
			router.push("/login");
			throw new Error("Response from Server: '" + response.data.errorDetails + "'");
		} else if (response.data.errorCode !== 0) {
			// let message = "Response from Server: '" + response.data.errorDetails + "'";
			let message = response.data.errorDetails;
			this.state.toast?.error(message);

			throw new Error(message);
		}

		if (!response.data.data) return {};

		if (response.data.time){
			this.state.serverTimeOffset = new Date() - response.data.time;
		}

		return response.data.data;
	}

	async wait(interval){
		return new Promise((resolve, reject) => {
			window.setTimeout(() => {
				resolve();
			}, interval);
		});
	}

	async queueCall(url, params) {
		return new Promise((resolve, reject) => {
			callQueue.push({ url, params, resolve, reject });

			if (callQueue.length === 1) this.processCallQueue();
		});
	}

	async processCallQueue(){
		let nextInQueue = callQueue[0];

		if (nextInQueue) {
			this.state.isAPIProcessing = true;
			try {
				let response = await fetch(nextInQueue.url, {
					method: "POST",
					headers: {
						"Content-Type": "application/json"
					},
					credentials: "include",
					body: JSON.stringify(nextInQueue.params)
				});

				response.data = await response.json();

				if (nextInQueue.resolve) nextInQueue.resolve(response);
				
			} catch(e) {
				nextInQueue.reject(e);
			}

			callQueue = callQueue.splice(1, 1);
			
			this.state.isAPIProcessing = false;

			this.processCallQueue();
		}
	}

	async call(cls, func, params, autoUpdateData) {
		let response;

		while(!response){
			try {
				response = await this.queueCall(`${this.baseURL}/${cls}/${func}`, params);
			} catch (e) {
				response = e.response;
			}

			// Retry if no response from server
			if (!response || response.data.errorCode === undefined) {
				await this.wait(1000);
				// TODO: Add callID in case server gets multiple of the same call?
				return await this.call(...arguments);
			}

			response = this.verifyResponse(response, autoUpdateData);
		}

		return response;
	}
}
