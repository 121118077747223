import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { state } from './store/store.js';

// Components
import TextInput from '@/components/TextInput.vue'
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

(async function() {
	try {
		state.user = await state.api.call("user", "getInfo", {});
		console.log(state.api);
		console.log(state.user);
	} catch (e) {
		console.error(e);
	}

	const app = createApp(App);
	app.component('RecycleScroller', RecycleScroller);
	app.use(router)
	
	app.mount('#app');
})();
