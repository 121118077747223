<script setup>
import { state } from '../store/store.js';
</script>

<template>
  <div v-if="!state.selectedCollaboration" style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
    <p>Please select a collaboration from the dropdown above.</p>
  </div>
  <div v-else>
    <div v-if="!state.contracts.length" style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
      <p>No Contracts found that are associated with your selected collaboration.</p>
    </div>
    <div>

      <div class="top-row">
        <select name="" id="" v-model="selectedPDF">
          <option v-for="contract in contracts" :key="contract[3].value" :value="contract">{{ contract[8].value }}</option>
        </select>

        <!-- http://localhost:3000/documents/viewFile?documentRID=9 -->
        <!-- :download="invoice[7].value?.url + '.pdf'" -->
        <!-- https://vocalmedia.quickbase.com/up/bthr5a6fc/a/r${documentRID}/e12/v0?usertoken=${global.server.quickbase.settings.userToken}` -->
        <a
          v-if="selectedPDF"
          :href="'https://vocalmedia.quickbase.com/up/bthr5a6fc/a/r' + selectedPDF[3].value + '/e12/v0'"
          rel="_blank"
          download
          style="text-decoration: none;"
        >
          <button class="download-btn btn-animate-hover" >
            <img style="cursor: pointer; margin-right: 6px;" src="@/assets/download-blue.svg" alt="download"/>
            <span>Download</span>
          </button>
        </a>
      </div>
      
      
      <!-- display a pdf -->
      <!-- <embed v-if="selectedPDF == 1" src="https://media.geeksforgeeks.org/wp-content/cdn-uploads/20210101201653/PDF.pdf#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&page=1&view=FitH" type="application/pdf" width="100%" height="600px" /> -->
      <embed v-if="selectedPDF" :src="state.api.baseURL + '/documents/viewFile?documentRID=' + selectedPDF[3].value + '#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&page=1&view=FitH'" type="application/pdf" width="100%" height="700" />
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Contract',
  components: {
    // HelloWorld
  },
  data() {
    return {
      selectedPDF: null,
      contracts: []
    }
  },
  async mounted() {
    this.filterContracts();
    this.selectedPDF = this.contracts[0];
  },
  methods: {
    filterContracts() {
      this.contracts = state.contracts.filter(contract => contract[12].value.url);
    }
    // async getContracts() {
    //   const response = await fetch(`http://localhost:3000/contracts/${this.$store.state.selectedCollaboration.id}`)
    //   const data = await response.json()
    //   this.$store.commit('setContracts', data)
    // }
  }
}
</script>

<style scoped>

.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 12px 0;
}
.download-btn {
  width: 140px;
  height: 40px;
  border-radius: 100px;

  background-color: white;

  font-size: 14px;
  font-weight: 600;
  color: #005FCC;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>