<script setup>
    import { state } from '../store/store.js';
</script>

<template>
	<div class="container">
		<div class="header">	
			<span style="font-size: 24px; font-weight: 600; margin: 0 auto 0 0;">Confirm</span>
			<button @click.prevent="closeMenu" class="btn btn-white btn-animate-hover" style="margin-right: 12px;">
				<span>Cancel</span>
			</button>
			<button @click="submit()" class="btn btn-animate-hover" :class="'btn-' + approvalType" >
				<span v-if="approvalType == 'green'">Green Light</span>
				<span v-else-if="approvalType == 'yellow'">Yellow Light</span>
				<span v-else-if="approvalType == 'red'">Red Light</span>
				<span v-else>Confirm</span>
			</button>
		</div>

		<div class="content">
			<span style="text-align: left; font-size: 13px; width: 100%; display: block; margin-bottom: 6px;">{{ state.user?.user.name }} Notes</span>
			<textarea v-model="notes" name="" class="notes-input" rows="4"> </textarea>
			<div class="divider"></div>

			<div class="creator-wrapper">
				<div class="img-wrapper">
					<img :src="state.api.baseURL + '/table_content/viewCreatorPhoto?creatorRID=' + creatorApproval[71].value"
						alt="" />
				</div>
				<div class="creator-name">
					<span style="font-size: 13px; text-align: left;">Creator</span>
					<span style="font-size: 14x; text-align: left; font-weight: 700; margin-top: 6px;">{{creatorApproval[83].value}}</span>
				</div>
			</div>
		</div>

	</div>
	<div id="curtain" @click="closeMenu()"></div>
</template>

<script>
export default {
	name: 'CreatorApproval',
	props: {
		msg: String,
		approvalType: String, // green, yellow, or red. Will determine how the submit button will look and the status type it will submit up to QB
		creatorApproval: Object
	},
	data() {
		return {
			notes: ''
		}
	},
	methods: {
		closeMenu() {
			state.creatorApprovalMenu.toggle();
		},
		async submit() {
			let response = await state.api.call('sourcing', 'updateFlag', {sourceRID: this.creatorApproval[3].value, flag: this.approvalType, notes: this.notes})
			console.log(response);

			state.creatorApprovalMenu.toggle();

			// Tell the parent component to update the creator approval list
			this.$emit('creator-updated');
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.creator-name {
	margin-left: 20px;

	display: flex;
	flex-direction: column;
	justify-content: center;
}
.creator-wrapper {
	display: flex;
}
.img-wrapper {
	width: 88px;
	height: 88px;

	border-radius: 100px;
	overflow: hidden;
}

.img-wrapper img {
	width: 88px;
	height: 88px;
}
.divider {
	width: 100%;
	height: 2px;
	background: #BFBDC1;

	margin: 32px 0;
	border-radius: 10px;
}
.notes-input {
	margin: 0;
	padding: 0;
	
	padding: 10px;

	min-height: 120px;

	width: 100%;
	max-width: 100%;
	max-width: -webkit-fill-available;
	max-width: -moz-available;          /* WebKit-based browsers will ignore this. */
    max-width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    max-width: fill-available;
	border-radius: 10px;

	resize: vertical;
}

.container .content {
	padding: 24px;
}
.header {
	display: flex;
	padding: 0 24px;
	height: 72px;
	background-color: #BFBDC1;


	align-items: center;
}
.container {
	background: white;

    display: flex;
    flex-direction: column;
	/* align-items: flex-end; */

    position: absolute;
	z-index: 10;

    right: 0;
    top: 0;
    height: 100vh;
	width: 480px; /*  234px to match figma literal */

	/* padding: 0 24px; */

}


.hamburger-section {
	/* padding-y: 24px; */
	padding: 13px 24px;
}

.close-icon {
	align-self: flex-end;
}
.divider {
	width: 100%;
	height: 2px;
	background: #BFBDC1;;
}

#copyright-text {
	font-size: 14px;
	font-weight: 400;
	color: #757575;

	width: 100%;
	margin-top: auto;
}

#curtain {
	position: absolute;
	z-index: 5;

	background: rgba(0,0,0,0.5);

	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

}

.btn {
	width: 140px;
	height: 40px;
	border-radius: 100px;

	background-color: white;

	font-size: 14px;
	font-weight: 600;
	color: black;

	display: flex;
	align-items: center;
	justify-content: center;

	border: 2px solid #102A18;
}

.btn-white {
  background-color: white;
  color: #005FCC;
}

.btn-green {
	background-color: #8ABD57;
}

.btn-yellow {
	background-color: #F9D54A;
}

.btn-red {
	background-color: #F16A6A;
}
</style>
