<script setup>
    import { state } from '../store/store.js';
</script>

<template>
	<div class="container">
		<div class="header">	
			<span style="font-size: 24px; font-weight: 600; margin: 0 auto 0 0;">Confirm</span>
			<button @click.prevent="closeMenu" class="btn btn-white btn-animate-hover" style="margin-right: 12px;">
				<span>Cancel</span>
			</button>
			<button @click="submit()" class="btn btn-animate-hover" :class="'btn-' + approvalType" v-if="approvalType !== 'history'">
				<span v-if="approvalType == 'green'">Green Light</span>
				<span v-else-if="approvalType == 'yellow'">Yellow Light</span>
				<span v-else-if="approvalType == 'red'">Red Light</span>
				<span v-else>Confirm</span>
			</button>
		</div>

		<div class="content" v-if="approvalType !== 'history'">
			<span style="text-align: left; font-size: 13px; width: 100%; display: block; margin-bottom: 6px;">{{ state.user?.user.name }} Notes</span>
			<textarea v-model="notes" name="" class="notes-input" rows="4"> </textarea>
			<div class="divider"></div>

			<div class="creator-wrapper">
				<div class="img-wrapper">
					<img :src="state.api.baseURL + '/table_content/viewCreatorPhoto?creatorRID=' + contentApproval[46].value"
						alt="" />
				</div>
				<div class="creator-name">
					<span style="font-size: 13px; text-align: left;">Creator</span>
					<span style="font-size: 14x; font-weight: 700; margin-top: 6px; min-height: 16px;">{{ contentApproval[30]?.value }}</span>
				</div>
			</div>
		</div>

		<div class="content-history" v-else>
			<div class="content-details">
				<div style="width: 100%;" v-if="selectedHistoryEntry">
					<div class="notes-section">
						<span style="font-size: 13px; line-height: 16px;">{{state.user?.user.name}} Notes</span>
						<!-- <p style="margin: 4px 0;">{{ 'selectedHistoryEntry[6].value' }}</p> old light status -->
						<!-- <p style="margin: 4px 0;">{{ selectedHistoryEntry[25].value }}</p> light status -->
						<p style="margin: 4px 0;">{{ selectedHistoryEntry[24]?.value }}</p> <!-- client notes -->
						<video :src="selectedHistoryEntry[28].value" v-if="selectedHistoryEntry[28].value" controls style="max-width: 100%;"></video>
						<div class="divider"></div>
						<div>
							<span style="font-size: 13px; line-height: 16px;">Vocal Media Notes</span>
							<p>
								<a :href="selectedHistoryEntry[28].value" v-if="selectedHistoryEntry[28].value" target="_blank">{{ selectedHistoryEntry[28].value }}</a>
							</p>

							<p class="link-wrapper" v-html="selectedHistoryEntry[23].value"></p>
						</div>
					</div>
					
					<div class="divider"></div>
					<div class="creator-wrapper" style="margin-bottom: 22px;">
						<div class="img-wrapper">
							<img :src="state.api.baseURL + '/table_content/viewCreatorPhoto?creatorRID=' + contentApproval[46].value"
								alt="" />
						</div>
						<div class="creator-name">
							<span style="font-size: 13px; text-align: left;">Creator</span>
							<!-- <span style="font-size: 14px; font-weight: 700; margin-top: 6px;  min-height: 16px;">{{ 'selectedHistoryEntry[10]?.value' }}</span> -->
							<span style="font-size: 14px; font-weight: 700; margin-top: 6px;  min-height: 16px;">{{ selectedHistoryEntry[30]?.value }}</span>
						</div>
					</div>
					<div class="posting-content">
						<img src="@/assets/document-text.png" style="margin-right: 8px; width: 24px; height: 24px;" alt="document-text">
						<div class="post">
							<!-- <span style="font-size: 13px; line-height: 16px; text-align: left;">Posting Content {{ 'formatDate(selectedHistoryEntry[2].value)' }}</span> -->
							<span style="font-size: 13px; line-height: 16px; text-align: left;">Posting Content {{ formatDate(selectedHistoryEntry[2]?.value) }}</span>
							<!-- <p style="margin: 6px 0 0 0; font-weight: 700; font-size: 14px; text-align: left; white-space: pre-wrap;">{{ 'selectedHistoryEntry[8].value' }}</p> -->
							<p style="margin: 6px 0 0 0; font-weight: 700; font-size: 14px; text-align: left; white-space: pre-wrap;" v-html="selectedHistoryEntry[11]?.value"></p>
						</div>
					</div>
				</div>

			</div>
			<div class="content-selection">
				<div style="display: flex; justify-content: flex-start; align-items: flex-center; margin-bottom: 6px;">
					<img src="@/assets/time.png" style="width:16px; height:16px; margin-right: 8px;" alt="time">
					<span>Draft History</span>
				</div>

				<div class="history-option" v-for="historyEntry in contentApproval.history" :key="historyEntry[3]" :class="{ selected: selectedHistoryEntry == historyEntry }" @click="selectedHistoryEntry = historyEntry">
					{{ state.unixToDate(historyEntry[2].value) }}
				</div>
			</div>

		</div>

		<!-- {{contentApproval}} -->

	</div>
	<div id="curtain" @click="closeMenu()"></div>
</template>

<script>
export default {
	name: 'ContentApproval',
	props: {
		msg: String,
		approvalType: String, // green, yellow, or red. Will determine how the submit button will look and the status type it will submit up to QB
		contentApproval: Object
	},
	data() {
		return {
			notes: '',
			selectedHistoryEntry: null
		}
	},
	mounted() {
		this.selectedHistoryEntry = this.contentApproval.history.at(-1);
	},
	methods: {
		closeMenu() {
			state.contentApprovalMenu.toggle();
		},
		formatDate(date) {
			// format a date to appear as "Tue 22 aug, 12:57am"
			let formattedDate = new Date(date);
			let day = formattedDate.toLocaleString('default', { weekday: 'short' });
			let month = formattedDate.toLocaleString('default', { month: 'short' });
			let hour = formattedDate.getHours();
			let minute = formattedDate.getMinutes();
			let ampm = hour >= 12 ? ' PM' : ' AM';
			hour = hour % 12;
			hour = hour ? hour : 12; // the hour '0' should be '12'
			minute = minute < 10 ? '0'+minute : minute;

			let strTime = hour + ':' + minute + ampm;

			return `${day} ${formattedDate.getDate()} ${month}, ${strTime}`;
			

		},
		async submit() {
			let response = await state.api.call('draft_entries', 'updateFlag', {draftRID: this.contentApproval[3].value, flag: this.approvalType, notes: this.notes})
			console.log(response);

			state.contentApprovalMenu.toggle();

			// Refresh data
			await state.refreshData();

			// Tell the parent component to update the content approval list
			this.$emit('content-updated');
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.post {
	display: flex;
	flex-direction: column;
}
.posting-content {
	width: 100%;
	display: flex;
	margin-bottom: 24px;
}
.notes-section {
	width: 100%;
	text-align: left;
}
.history-option {
	padding: 13px 18px;
	cursor: pointer;
	border-radius: 100px;
	text-align: left;
}
.selected {
	background: #F9D54A;
}
.content-selection {
	display: flex;
	flex-direction: column;
	width: 240px;

	padding: 24px;
	border-left: 2px solid #BFBDC1;
}
.content-details {
	display: flex;
	flex-direction: column;
	width: 640px;
	height: 100%;
	padding: 24px;

	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */
}
.content-history {
	display: flex;
	height: 100%;
}
.creator-name {
	margin-left: 20px;

	display: flex;
	flex-direction: column;
	justify-content: center;
}
.creator-wrapper {
	display: flex;
}
.link-wrapper a {
	word-break: break-word;
}
.img-wrapper {
	width: 88px;
	height: 88px;

	border-radius: 100px;
	overflow: hidden;
}

.img-wrapper img {
	width: 88px;
	height: 88px;
}
.divider {
	width: 100%;
	height: 2px;
	background: #BFBDC1;

	margin: 32px 0;
	border-radius: 10px;
}
.notes-input {
	margin: 0;
	padding: 0;
	
	padding: 10px;

	min-height: 120px;

	width: 100%;
	max-width: 100%;
	max-width: -webkit-fill-available;
	max-width: -moz-available;          /* WebKit-based browsers will ignore this. */
    max-width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    max-width: fill-available;
	border-radius: 10px;

	resize: vertical;
}

.container .content {
	padding: 24px;
}
.header {
	display: flex;
	padding: 0 24px;
	height: 72px;
	min-height: 72px;
	background-color: #BFBDC1;


	align-items: center;
}
.container {
	background: white;

    display: flex;
    flex-direction: column;
	/* align-items: flex-end; */

    position: absolute;
	z-index: 10;

    right: 0;
    top: 0;
    height: 100vh;
	min-width: 480px; /*  234px to match figma literal */

	/* padding: 0 24px; */

	overflow-y: scroll;

}


.hamburger-section {
	/* padding-y: 24px; */
	padding: 13px 24px;
}

.close-icon {
	align-self: flex-end;
}
.divider {
	width: 100%;
	height: 2px;
	background: #BFBDC1;;
}

#copyright-text {
	font-size: 14px;
	font-weight: 400;
	color: #757575;

	width: 100%;
	margin-top: auto;
}

#curtain {
	position: absolute;
	z-index: 5;

	background: rgba(0,0,0,0.5);

	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

}

.btn {
	width: 140px;
	height: 40px;
	border-radius: 100px;

	background-color: white;

	font-size: 14px;
	font-weight: 600;
	color: black;

	display: flex;
	align-items: center;
	justify-content: center;

	border: 2px solid #102A18;
}

.btn-white {
  background-color: white;
  color: #005FCC;
}

.btn-green {
	background-color: #8ABD57;
}

.btn-yellow {
	background-color: #F9D54A;
}

.btn-red {
	background-color: #F16A6A;
}
</style>
