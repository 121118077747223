<script setup>
import { state } from '../store/store.js';
import CreatorApproval from '@/components/CreatorApproval.vue';
import ContentApproval from '@/components/ContentApproval.vue';
</script>


<template>
	<div id="info-bar">
		<img style="cursor: pointer; margin-right: 12px;" src="@/assets/arrow-back.png" @click="$router.push('/campaigns')" alt="back" />
		<div class="info-bar-group">
			<div class="title">Collaboration</div>
			<div class="text">{{ state.selectedCollaboration[55].value }}</div>
		</div>
		<div class="divider"></div>
		<div class="info-bar-group">
			<div class="title">Campaign</div>
			<div class="text">{{ state.selectedCampaign[13].value }}</div>
		</div>
		<div class="info-bar-group" style="display: none;">
			<div class="title">Campaign Manager(s)</div>
			<div class="text" v-for="manager of state.selectedCampaign[142].value" :key="manager.email">{{ manager.name}}</div>
		</div>
	</div>
	<div id="navbar">
		<div id="nav-btn-container">
			<div @click="step = 1" class="nav-btn" :class="{ 'button-active': step == 1 }">
				Creator Approvals
			</div>
			<div @click="step = 2" class="nav-btn" :class="{ 'button-active': step == 2 }">
				Content Approvals
			</div>
			<div @click="step = 3" class="nav-btn" :class="{ 'button-active': step == 3 }">
				Live Content & Metrics
			</div>
		</div>

		<div id="nav-btn-container" style="margin-left: auto;" v-if="step === 1">
			<div class="nav-btn" @click="downloadCreatorApprovalsAsCSV();">
				<img style="cursor: pointer; margin-right: 6px;" src="@/assets/download-blue.svg" alt="copy"/>
				<span>Download</span>
			</div>
		</div>

		

		<div id="nav-btn-container" style="margin-left: auto;" v-if="step === 1">
			<div @click="selectedFilter = 'pending'" class="nav-btn" :class="{ 'button-active': selectedFilter == 'pending' }">
				Pending
			</div>
			<div @click="selectedFilter = 'approved'" class="nav-btn" :class="{ 'button-active': selectedFilter == 'approved' }">
				Approved
			</div>
			<div @click="selectedFilter = 'rejected'" class="nav-btn" :class="{ 'button-active': selectedFilter == 'rejected' }">
				Not Approved
			</div>
		</div>

		<div id="nav-btn-container" style="margin-left: auto;" v-if="step === 2">
			<div @click="selectedFilter = 'pending'" class="nav-btn" :class="{ 'button-active': selectedFilter == 'pending' }">
				Pending
			</div>
			<div @click="selectedFilter = 'rejected'" class="nav-btn" :class="{ 'button-active': selectedFilter == 'rejected' }">
				In Redraft
			</div>
			<div @click="selectedFilter = 'approved'" class="nav-btn" :class="{ 'button-active': selectedFilter == 'approved' }">
				Approved
			</div>
			
		</div>
		
		<div class="select-container mx-4" style="margin-left: auto;" v-if="step === 3">
			<select v-model="selectedMonth" @change="filterContentByMonth" id="collaboration-select" name="month-filter-select">
				<option value="" style="color: gray;">Filter by Month</option>
				<option v-for="month in months" :key="month.value" :value="month.value">{{ month.text }}</option>
			</select>
		</div>
		<div id="nav-btn-container" style="margin-left: 12px;" v-if="step === 3">
			<div class="nav-btn" @click="downloadContentMetricsAsCSV();">
				<img style="cursor: pointer; margin-right: 6px;" src="@/assets/download-blue.svg" alt="copy"/>
				<span>Download Metrics</span>
			</div>
		</div>
	</div>
	<div class="content" v-if="step == 1">
		<div v-if="!creatorApprovals.length" style="display: flex; justify-content: center; align-items: center; height: 100%;">The currently selected campaign has no associated Creator Approvals.</div>
		<div v-else class="creator-approval-card" v-for="(creatorApproval, index) in creatorApprovals" :key="index">
			<!-- {{creatorApproval}} -->
			<div class="creator-info">
				<div class="left">
				<div class="img-wrapper">
					<img :src="state.api.baseURL + '/table_content/viewCreatorPhoto?creatorRID=' + creatorApproval[71].value"
						alt="" />
				</div>
				<span class="label">Creator</span>
				<span class="value wt-700">{{ creatorApproval[83].value }}</span>
				<!-- state.selectedCollaboration[87].value == true -->
				<span class="label" v-if="creatorApproval[104].value && state.selectedCollaboration[87].value == true">City, State</span>
				<span class="value wt-700" v-if="state.selectedCollaboration[87].value == true"> {{ creatorApproval[104].value }}</span>
			</div>
			<div class="right">
				<div class="creator-column" style="flex: 1">
					<div class="social-follower-section">
						<div v-if="creatorApproval[193].value" class="social-icon-wrapper">
							<a :href="sanitizeURL(creatorApproval[193].value)" target="_blank"><img src="@/assets/Instagram.png" alt=""></a>
						</div>
						<div v-if="creatorApproval[193].value" class="column">
							<span style="font-size: 13px;" class="wt-700">Followers</span>
							<span style="font-size: 13px; line-height: 18px;" class="wt-700">{{ state.toFormattedInteger(creatorApproval[114].value) }}</span>
						</div>
					</div>
					<div class="social-follower-section">
						<div v-if="creatorApproval[192].value" class="social-icon-wrapper">
							<a :href="sanitizeURL(creatorApproval[192].value)" target="_blank"><img src="@/assets/TikTok.png" alt=""></a>
						</div>
						<div v-if="creatorApproval[192].value" class="column">
							<span style="font-size: 13px;" class="wt-700">Followers</span>
							<span style="font-size: 13px; line-height: 18px;" class="wt-700">{{ state.toFormattedInteger(creatorApproval[116].value) }}</span>
						</div>
					</div>

					

					<div class="social-follower-section">
						<div v-if="creatorApproval[118].value" class="social-icon-wrapper">
							<a href="https://www.facebook.com" target="_blank"><img src="@/assets/Facebook.png" alt=""></a>
						</div>
						<div v-if="creatorApproval[118].value" class="column">
							<span style="font-size: 13px;" class="wt-700">Followers</span>
							<span style="font-size: 13px; line-height: 18px;" class="wt-700">{{ state.toFormattedInteger(creatorApproval[118].value) }}</span>
						</div>
					</div>
					

					<div class="social-follower-section">
						<div v-if="creatorApproval[194].value" class="social-icon-wrapper">
							<a :href="sanitizeURL(creatorApproval[194].value)" target="_blank"><img src="@/assets/Youtube.png" alt=""></a>
						</div>
						<div v-if="creatorApproval[194].value" class="column">
							<span style="font-size: 13px;" class="wt-700">Followers</span>
							<span style="font-size: 13px; line-height: 18px;" class="wt-700">{{ state.toFormattedInteger(creatorApproval[198].value) }}</span>
							<!-- <span style="font-size: 13px;">{{ state.toFormattedInteger(creatorApproval[121].value) }}</span> -->
						</div>
					</div>
					

				</div>
				<div class="creator-column" style="flex: 2">
					<div style="text-align: left;">
						<div style="text-align: left; font-weight: bold;">Vocal Notes</div>
						<p style="margin: 0;font-size: 14px; overflow-y: auto; height: 166px;">{{ creatorApproval[30].value }}</p>
					</div>
				</div>
				<div class="creator-column" style="flex: 2">
					<div class="tags-container">
						<div style="text-align: left; font-weight: bold;">Client Notes</div>
						<p style="margin: 0;font-size: 14px; overflow-y: auto; height: 166px; text-align: left;">{{ creatorApproval[87].value }}</p>
					</div>
					<div class="tags-container" v-if="state.selectedCollaboration[88].value == true">
						<span style="font-size: 13px; font-weight: 700; text-align: left;">Creator Themes</span>
						<div class="tags-list">
							<span class="tag-item" v-for="(tag, index) in creatorApproval[239]?.value" :key="index">{{ tag }}</span>
						</div>
					</div>
					<div class="tags-container" v-if="state.selectedCollaboration[89].value == true">
						<span style="font-size: 13px; font-weight: 700; text-align: left;">Creator Identities</span>
						<div class="tags-list">
							<span class="tag-item" v-for="(tag, index) in creatorApproval[237]?.value" :key="index">{{ tag }}</span>
						</div>
					</div>
					<div class="tags-container" v-if="state.selectedCollaboration[90].value == true">
						<span style="font-size: 13px; font-weight: 700; text-align: left;">Creator Issue Interests</span>
						<div class="tags-list">
							<span class="tag-item" v-for="(tag, index) in creatorApproval[238]?.value" :key="index">{{ tag }}</span>
						</div>
					</div>
				</div>
				<!-- <div class="stats-row">
					<div v-if="creatorApproval[193].value" class="social-icon-wrapper">
						<a :href="sanitizeURL(creatorApproval[193].value)" target="_blank"><img src="@/assets/Instagram.png" alt=""></a>
					</div>
					<div v-if="creatorApproval[193].value" class="column">
						<span style="font-size: 13px;"><span class="wt-700">Followers</span>/Views</span>
						<span style="font-size: 13px; line-height: 18px;" class="wt-700">{{ state.toFormattedInteger(creatorApproval[114].value) }}</span>
						<span style="font-size: 13px;">{{ state.toFormattedInteger(creatorApproval[115].value) }}</span>
					</div>

					<div v-if="creatorApproval[192].value" class="social-icon-wrapper">
						<a :href="sanitizeURL(creatorApproval[192].value)" target="_blank"><img src="@/assets/TikTok.png" alt=""></a>
					</div>
					<div v-if="creatorApproval[192].value" class="column">
						<span style="font-size: 13px;"><span class="wt-700">Followers</span>/Views</span>
						<span style="font-size: 13px; line-height: 18px;" class="wt-700">{{ state.toFormattedInteger(creatorApproval[116].value) }}</span>
						<span style="font-size: 13px;">{{ state.toFormattedInteger(creatorApproval[117].value) }}</span>
					</div>

					<div v-if="creatorApproval[118].value" class="social-icon-wrapper">
						<a href="https://www.facebook.com" target="_blank"><img src="@/assets/Facebook.png" alt=""></a>
					</div>
					<div v-if="creatorApproval[118].value" class="column">
						<span style="font-size: 13px;"><span class="wt-700">Followers</span>/Views</span>
						<span style="font-size: 13px; line-height: 18px;" class="wt-700">{{ state.toFormattedInteger(creatorApproval[118].value) }}</span>
						<span style="font-size: 13px;">{{ state.toFormattedInteger(creatorApproval[119].value) }}</span>
					</div>

					<div v-if="creatorApproval[194].value" class="social-icon-wrapper">
						<a :href="sanitizeURL(creatorApproval[194].value)" target="_blank"><img src="@/assets/Youtube.png" alt=""></a>
					</div>
					<div v-if="creatorApproval[194].value" class="column">
						<span style="font-size: 13px;"><span class="wt-700">Followers</span>/Views</span>
						<span style="font-size: 13px; line-height: 18px;" class="wt-700">{{ state.toFormattedInteger(creatorApproval[198].value) }}</span>
						<span style="font-size: 13px;">{{ state.toFormattedInteger(creatorApproval[121].value) }}</span>
					</div>
					<div>
						<div>Notes</div>
						<span style="font-size: 14px;">{{ creatorApproval[30].value }}</span>
					</div>
				</div> -->
				<!-- <div class="tags-row">
					<div class="themes-container">
						<span style="font-size: 13px; font-weight: 700; text-align: left;">Creator Themes</span>
						<div class="themes-list">
							<span class="tag-item" v-for="(theme, index) in creatorApproval[220].value" :key="index" v-html="theme"></span>
						</div>
					</div>
					<div class="identities-container">
						<span style="font-size: 13px; font-weight: 700; text-align: left;">Creator Identities</span>
						<div class="identities-list">
							<span class="tag-item" v-for="(theme, index) in creatorApproval[221].value" :key="index" v-html="theme"></span>
						</div>
					</div>
				</div> -->
				
			</div>
			</div>
			
			<div class="buttons-row" v-if="selectedFilter === 'pending'">
				<button class="btn-green btn-animate-hover"  @click="openCreatorApproval('green', creatorApproval)">Green Light</button>
				<button class="btn-yellow btn-animate-hover" @click="openCreatorApproval('yellow', creatorApproval)">Yellow Light</button>
				<button class="btn-red btn-animate-hover"    @click="openCreatorApproval('red', creatorApproval)">Red Light</button>
				<button class="btn-white btn-animate-hover"  @click="openCreatorApproval('', creatorApproval)">Notes</button>
			</div>

			<div class="buttons-row" v-if="selectedFilter !== 'pending'">
				<button class="btn-yellow btn-animate-hover" @click="revertCreatorToPending(creatorApproval)">Revert to Pending</button>
				<button class="btn-white btn-animate-hover"  @click="openCreatorApproval('', creatorApproval)">Notes</button>
			</div>
		</div>
	</div>
	<div class="content" v-if="step == 2">
		<div v-if="!contentApprovals.length" style="display: flex; justify-content: center; align-items: center; height: 100%;">The currently selected campaign has no associated Content Approvals.</div>
		<div v-else class="content-approval-card" v-for="(contentApproval, index) in contentApprovals" :key="index">
			<div class="left" style="margin-right: 10px;">
				<div class="img-wrapper">
					<img :src="state.api.baseURL + '/table_content/viewCreatorPhoto?creatorRID=' + contentApproval[46].value"
						alt="" />
				</div>
				<span class="label">Creator</span>
				<span class="value wt-700">{{ contentApproval[30].value }}</span>
				<span class="label" v-if="contentApproval[40].value">City, State</span>
				<span class="value wt-700">{{ contentApproval[40].value }}</span>
			</div>
			<div class="creator-column" style="margin-right: 8px;">
				<div class="social-follower-section">
					<div v-if="contentApproval[65].value" class="social-icon-wrapper">
						<a :href="sanitizeURL(contentApproval[65].value)" target="_blank"><img src="@/assets/Instagram.png" alt=""></a>
					</div>
				</div>


				<div class="social-follower-section">
					<div v-if="contentApproval[66].value" class="social-icon-wrapper">
						<a :href="sanitizeURL(contentApproval[66].value)" target="_blank"><img src="@/assets/TikTok.png" alt=""></a>
					</div>
				</div>

				<div class="social-follower-section">
					<div v-if="contentApproval[67].value" class="social-icon-wrapper">
						<a :href="sanitizeURL(contentApproval[67].value)" target="_blank"><img src="@/assets/Youtube.png" alt=""></a>
					</div>
				</div>
				

			</div>
			<div class="right">
				<div class="details-row">
					<div class="column">
						<!-- <span style="font-size: 13px;">{{ contentApproval[9].value }}</span> -->
						<span class="link-wrapper" style="font-size: 13px;" v-html="contentApproval[11].value"></span> <!-- contentApproval[18].value + ". " + state.unixToDate(contentApproval[19].value) -->
						<span class="wt-700" style="font-size: 14px;" v-if="contentApproval[27].value">{{ contentApproval[27].value }}</span>
						<video :src="contentApproval[28].value" v-if="contentApproval[28].value" controls></video>
					</div>
					<div class="column">
						<span style="font-size: 13px;">
							Vocal Media Feedback
							
							<span v-if="contentApproval[25].value == 'Green Light'" class="status status-green">{{contentApproval[25].value}}</span>
							<span v-else-if="contentApproval[25].value == 'Yellow Light'" class="status status-yellow">{{contentApproval[25].value}}</span>
							<span v-else-if="contentApproval[25].value == 'Red Light'" class="status status-red">{{contentApproval[25].value}}</span>
						</span>
						<strong style="font-size: 13px; padding-top: 2ch; font-weight: bold;">Vocal Notes</strong>
						<span style="font-size: 13px;">{{ contentApproval[23].value }}</span>
					</div>
					<div class="column">
						<span style="font-size: 13px; font-weight: bold;">Client Notes</span>
						<span style="font-size: 14px;">{{ contentApproval[24].value }}</span>
					</div>
				</div>
				<div class="buttons-row">
					<button @click="openContentApproval('green', contentApproval)" class="btn-green btn-animate-hover" v-if="selectedFilter === 'pending'">Green Light</button>
					<button @click="openContentApproval('yellow', contentApproval)" class="btn-yellow btn-animate-hover" v-if="selectedFilter === 'pending'">Yellow Light</button>
					<button @click="openContentApproval('red', contentApproval)" class="btn-red btn-animate-hover" v-if="selectedFilter === 'pending'">Red Light</button>
					<button @click="openContentApproval('history', contentApproval)" class="btn-white btn-animate-hover" v-if="contentApproval.history?.length">Draft History</button>
					<button @click="openContentApproval('', contentApproval)" class="btn-white btn-animate-hover">Notes</button>
				</div>
			</div>
		</div>
	</div>

	<!-- Content & Metrics -->
	<div class="content" v-if="step == 3">
		<div v-show="!contents.length && !selectedMonth" style="display: flex; justify-content: center; align-items: center; height: 100%;">The currently selected campaign has no associated Content.</div>
		<div v-show="!contents.length && selectedMonth" style="display: flex; justify-content: center; align-items: center; height: 100%;">No associated Content found for the selected month.</div>
		<div v-show="contents.length" class="creator-metrics-container">
			
			<!-- top line metrics -->
			<div style="display: flex; justify-content: space-around; flex: 1;">
				<!-- <div style="position: relative">
					<span style="position: absolute; font-weight: 700; top: 0; left: 0;">Total Metrics</span>
				</div> -->
				<div class="metric">
					<img src="@/assets/views.png" alt="views">
					<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
						<span class="metric-title">Views</span>
						<span class="metric-value">{{ state.toFormattedInteger(sumUpMetric(9)) }}</span>
					</div>
				</div>
				<div class="metric">
					<img src="@/assets/heart.svg" alt="likes">
					<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
						<span class="metric-title">Likes</span>
						<span class="metric-value">{{ state.toFormattedInteger(sumUpMetric(10)) }}</span>
					</div>
				</div>
				<div class="metric">
					<img src="@/assets/comments.png" alt="comments">
					<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
						<span class="metric-title">Comments</span>
						<span class="metric-value">{{ state.toFormattedInteger(sumUpMetric(11)) }}</span>
					</div>
				</div>
				<div class="metric">
					<img src="@/assets/shares.png" alt="shares">
					<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
						<span class="metric-title">Shares</span>
						<span class="metric-value">{{ state.toFormattedInteger(sumUpMetric(12)) }}</span>
					</div>
				</div>
				<div class="metric" v-if="state.selectedCollaboration[91].value == true">
					<img src="@/assets/saves.png" alt="saves">
					<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
						<span class="metric-title">Saves</span>
						<span class="metric-value">{{ state.toFormattedInteger(sumUpMetric(46)) }}</span>
					</div>
				</div>
				<div class="metric" v-if="state.selectedCollaboration[93].value == true">
					<img src="@/assets/clicks.svg" alt="saves">
					<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
						<span class="metric-title">Action URL Clicks</span>
						<span class="metric-value"></span>
					</div>
				</div>
				<div class="metric">
					<img src="@/assets/stats-chart.png" alt="stats-chart">
					<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
						<span class="metric-title">Total Engagement</span>
						<span class="metric-value">{{ state.toFormattedInteger(sumUpMetric(13)) }}</span>
					</div>
				</div>
			</div>
		</div>
		<div v-show="contents.length" class="creator-metrics-container" v-for="content in contents" :key="content[3].value">
			<div class="creator-details">
				<div class="img-wrapper">
					<img :src="state.api.baseURL + '/table_content/viewContentPhoto?contentRID=' + content[3].value"
						alt="" @click="openLivePostURL(content[8].value);" style="cursor: pointer;"/>
				</div>
				<span class="label">Creator</span>
				<span class="value wt-700">{{ content[38].value }}</span>
				<span class="label" v-if="content[160].value">City, State</span>
				<span class="value wt-700"> {{ content[160].value }}</span>
			</div>
			<div class="content-list">
				
				<div class="content-item">
					<div class="social-icon-wrapper" style="">
						<a v-if="content[63].value.includes('Instagram')" :href="sanitizeURL(content[167].value)" target="_blank">
							<img src="@/assets/Instagram.png" alt="Instagram">
						</a>
						<a v-else-if="content[63].value == 'TikTok'" :href="sanitizeURL(content[165].value)" target="_blank">
							<img src="@/assets/TikTok.png" alt="TikTok">
						</a>
						<a v-else-if="content[63].value == 'Twitter'" href="https://www.twitter.com/" target="_blank">
							<img src="@/assets/TwitterX.png" alt="Twitter">
						</a>
						<a v-else-if="content[63].value == 'YouTube' || content[63].value == 'YT Shorts'" :href="sanitizeURL(content[166].value)" target="_blank">
							<img src="@/assets/Youtube.png" alt="YouTube">
						</a>
						<a v-else-if="content[63].value == 'Facebook'" href="https://www.facebook.com/" target="_blank">
							<img src="@/assets/Facebook.png" alt="Facebook">
						</a>
					</div>
					<div class="metrics-divider"></div>
					<div class="content-details">

						<div class="metrics-row">
							<div class="metric">
								<img src="@/assets/favicon.png" alt="views">
								<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
									<span class="metric-title">Notes</span>
									<span class="metric-value">{{ content[210].value }}</span>
								</div>
							</div>
							<div class="metric" style="margin-left: auto; flex: none; margin-top: none;">
								<button class="download-btn btn-animate-hover" @click="downloadPostVideo(content[217].value);" v-if="content[217].value">
									<img style="cursor: pointer; margin-right: 6px;" src="@/assets/download-blue.svg" alt="download"/>
									<span>Raw File Download</span>
								</button>
								&nbsp;&nbsp;
								<button class="download-btn btn-animate-hover" @click="openLivePostURL(content[8].value);">
									<img style="cursor: pointer; margin-right: 6px;" src="@/assets/link.svg" alt="live post"/>
									<span>Live Post</span>
								</button>
							</div>

							
						</div>

						<div class="metrics-row" style="margin-top: 12px;">
							<div class="metric">
								<img src="@/assets/views.png" alt="views">
								<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
									<span class="metric-title">Views</span>
									<span class="metric-value">{{ state.toFormattedInteger(content[9]?.value) }}</span>
								</div>
							</div>
							<div class="metric">
								<img src="@/assets/heart.svg" alt="likes">
								<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
									<span class="metric-title">Likes</span>
									<span class="metric-value">{{ state.toFormattedInteger(content[10]?.value) }}</span>
								</div>
							</div>
							<div class="metric">
								<img src="@/assets/comments.png" alt="comments">
								<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
									<span class="metric-title">Comments</span>
									<span class="metric-value">{{ state.toFormattedInteger(content[11]?.value) }}</span>
								</div>
							</div>
							<div class="metric">
								<img src="@/assets/shares.png" alt="shares">
								<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
									<span class="metric-title">Shares</span>
									<span class="metric-value">{{ state.toFormattedInteger(content[12]?.value) }}</span>
								</div>
							</div>
							<div class="metric">
								<img src="@/assets/stats-chart.png" alt="stats-chart">
								<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
									<span class="metric-title">Total Engagement</span>
									<span class="metric-value">{{ state.toFormattedInteger(content[13]?.value) }}</span>
								</div>
							</div>
						</div>

						<div class="metrics-row" style="margin-top: 12px; border-bottom: none;">
							<div class="metric">
								<img src="@/assets/document-text.png" alt="views">
								<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
									<span class="metric-title">Post Type</span>
									<span class="metric-value">{{ content[63]?.value }}</span>
								</div>
							</div>
							<div class="metric">
								<img src="@/assets/calendar-clear.svg" alt="likes">
								<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
									<span class="metric-title">Post Date</span>
									<span class="metric-value">{{ state.unixToDate(content[14]?.value) }}</span>
								</div>
							</div>
							<div class="metric" v-if="state.selectedCollaboration[92].value == true">
								<img src="@/assets/calendar-month.svg" alt="shares">
								<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
									<span class="metric-title">Post Month</span>
									<span class="metric-value">{{ getMonth(content[14]?.value) }}</span>
								</div>
							</div>
							<div class="metric" v-if="state.selectedCollaboration[91].value == true">
								<img src="@/assets/saves.png" alt="saves">
								<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
									<span class="metric-title">Saves</span>
									<span class="metric-value">{{ state.toFormattedInteger(content[46]?.value) }}</span>
								</div>
							</div>
							<div class="metric" v-if="state.selectedCollaboration[93].value == true">
								<img src="@/assets/clicks.svg" alt="stats-chart">
								<div style="display: flex; flex-direction: column; justify-content: space-evenly;">
									<span class="metric-title">Action URL Clicks</span>
									<span class="metric-value"></span>
								</div>
							</div>
						</div>

						
					</div>
					
				</div>
				
			</div>
		</div>
	</div>

	<creator-approval
		v-if="state.creatorApprovalMenu.display"
		:approval-type="approvalType"
		:creator-approval="selectedCreatorApproval"
		@creator-updated="refreshData"
	></creator-approval>

	<content-approval
		v-if="state.contentApprovalMenu.display"
		:approval-type="approvalType"
		:content-approval="selectedContentApproval"
		@content-updated="refreshData"
	></content-approval>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
	name: 'Campaign',
	// Add props object
	// props: {
	// 	id: {
	// 		type: String,
	// 		required: true
	// 	}
	// },
	// data object
	data() {
		return {
			step: 1,
			selectedFilter: 'pending', // pending, approved, rejected
			creatorApprovals: [],
			contentApprovals: [],
			contents: [], // a single 'content' is a single post by an influencer to be displayed on the metrics page
			selectedCreatorApproval: {},
			selectedContentApproval: {},
			approvalType: '',
			months: [
				{ text: 'January', value: 1 },
				{ text: 'February', value: 2 },
				{ text: 'March', value: 3 },
				{ text: 'April', value: 4 },
				{ text: 'May', value: 5 },
				{ text: 'June', value: 6 },
				{ text: 'July', value: 7 },
				{ text: 'August', value: 8 },
				{ text: 'September', value: 9 },
				{ text: 'October', value: 10 },
				{ text: 'November', value: 11 },
				{ text: 'December', value: 12 }
			],
			selectedMonth: "",
			creatorThemes: [],
			creatorIdentities: [],

		}
	},
	mounted() {
		this.creatorApprovals = this.filterCreatorApprovals();
		this.contentApprovals = this.filterContentApprovals();
		this.contents = this.filterContents();
	},
	methods: {
		openCreatorApproval(approvalType, creatorApproval) {
			this.approvalType = approvalType; // Colors red, yellow, green
			this.selectedCreatorApproval = creatorApproval;
			state.creatorApprovalMenu.display = true;
		},
		openContentApproval(approvalType, contentApproval) {
			this.approvalType = approvalType; // red, yellow, green
			this.selectedContentApproval = contentApproval;
			state.contentApprovalMenu.display = true;
		},
		filterCreatorApprovals() { // sourcings
			let sourcings = state.sourcings.filter(sourcing => sourcing[58].value == this.$route.params.id);
			if (this.selectedFilter == 'pending') {
				sourcings = sourcings.filter(sourcing => { if (sourcing[206].value == '' || !sourcing[206].value || sourcing[206].value == 'Light') return true; });
			} else if (this.selectedFilter == 'approved') {
				sourcings = sourcings.filter(sourcing => sourcing[206].value == 'Green Light' || sourcing[206].value == 'Yellow Light');
				// sourcings = sourcings.filter(sourcing => { if (sourcing[206].value == 'Green Light' || sourcing[206].value == 'Yellow Light') return true; });
			} else if (this.selectedFilter == 'rejected') {
				sourcings = sourcings.filter(sourcing => sourcing[206].value == 'Red Light');
			}
			return sourcings;
		},
		filterContentApprovals() { // draft_entries
			let draftEntries = state.draftEntries.filter(entry => entry[29].value == this.$route.params.id);
			if (this.selectedFilter == 'pending') {
				draftEntries = draftEntries.filter(entry => { if (entry[20].value == '' || !entry[20].value) return true; });
			} else if (this.selectedFilter == 'approved') {
				draftEntries = draftEntries.filter(entry => entry[20].value == 'Green Light');
			} else if (this.selectedFilter == 'rejected') {
				draftEntries = draftEntries.filter(entry => entry[20].value == 'Red Light' || entry[20].value == 'Yellow Light');
			}
			return draftEntries;
		},
		filterContents() {
			return state.contents.filter(content => content[48].value == this.$route.params.id);
		},
		async refreshData() {
			console.log('refreshing data')
			await state.refreshDataSourcings();
			await state.refreshDataDraftEntries();

			this.creatorApprovals = this.filterCreatorApprovals();
			this.contentApprovals = this.filterContentApprovals();
			this.contents = this.filterContents();
		},
		async revertCreatorToPending(creatorApprovalRecord) {
			let response = await state.api.call('sourcing', 'updateFlag', {sourceRID: creatorApprovalRecord[3].value, flag: "", notes: "Reverted to pending."})
			console.log(response);

			await this.refreshData();
		},
		copyToClipboard(value) {
			navigator.clipboard.writeText(value);
		},
		openLivePostURL(url) {
			url = this.sanitizeURL(url);
			window.open(url, '_blank');
		},
		downloadPostVideo(url) {
			// Download the video file from the URL (without navigating to the URL)
			let link = document.createElement('a');
			link.href = url;
			link.download = 'video.mp4';
			link.target = '_blank';
			link.click();

		},
		sanitizeURL(url) {
			// Add https:// to the beginning of a URL if it doesn't already have it
			if (!url.startsWith('http://') && !url.startsWith('https://')) {
				url = 'https://' + url;
			}
			return url;
		},
		downloadCreatorApprovalsAsCSV() {
			// Grab all creator approvals for the campaign, ignore the not approved/approved/pending filter
			let creatorApprovals = state.sourcings.filter(sourcing => sourcing[58].value == this.$route.params.id);
			console.log(creatorApprovals);
			let csvContent = "data:text/csv;charset=utf-8,";
			csvContent += "Status,Creator Name,Instagram URL,TikTok URL,YouTube URL,Creator Identity,Creator Themes,Creator Issue Interests,Vocal Client Notes,Client Notes\n";
			function escapeValue(val) {
				if (!val) return "";
				val = val.toString();
				if (val.includes(',')) {
					val = '"' + val + '"';
				}
				return val;
			}
			creatorApprovals.forEach(creator => {
				let row = escapeValue(creator[206].value) + ',' + escapeValue(creator[83].value) + ',' + escapeValue(creator[193].value) + ',' + escapeValue(creator[192].value) + ',' + escapeValue(creator[194].value) + ',' + escapeValue(creator[142].value) + ',' + escapeValue(creator[143].value) + ',' + escapeValue(creator[95].value) + ',' + escapeValue(creator[30].value) + ',' + escapeValue(creator[87].value);
				csvContent += row + "\n";
			});
			let encodedUri = encodeURI(csvContent);
			let link = document.createElement("a");
			let filename = state.user?.user?.name + '_' + state.selectedCollaboration[55].value + '_' + state.selectedCampaign[13].value + ".csv"; // Client Name_Collaboration Name_Campaign Name
			link.setAttribute("href", encodedUri);
			link.setAttribute("download", filename);
			document.body.appendChild(link); // Required for FF
			link.click();
		},
		downloadContentMetricsAsCSV() {
			let csvContent = "data:text/csv;charset=utf-8,";
			csvContent += "Creator,Platform,Views,Likes,Comments,Shares,Saves,Total Engagement,Posted On,Last Updated,Live Post\n";
			function escapeValue(val) {
				console.log('escape func:', val);
				if (!val) return "";
				val = val.toString();
				if (val.includes(',')) {
					val = '"' + val + '"';
				}
				return val;
			}
			this.contents.forEach(content => {
				console.log(content[38].value) + ',' + escapeValue(content[63].value) + ',' + escapeValue(content[9].value) + ',' + escapeValue(content[10].value) + ',' + escapeValue(content[11].value) + ',' + escapeValue(content[12].value) + ',' + escapeValue(content[46].value) + ',' + escapeValue(content[13].value) + ',' + state.unixToDate(content[14].value) + ',' + state.unixToDate(content[47].value) + ',' + escapeValue(content[8].value);

				let row = escapeValue(content[38].value) + ',' + escapeValue(content[63].value) + ',' + escapeValue(content[9].value) + ',' + escapeValue(content[10].value) + ',' + escapeValue(content[11].value) + ',' + escapeValue(content[12].value) + ',' + escapeValue(content[46].value) + ',' + escapeValue(content[13].value) + ',' + state.unixToDate(content[14].value) + ',' + state.unixToDate(content[47].value) + ',' + escapeValue(content[8].value);
				csvContent += row + "\n";
			});
			let encodedUri = encodeURI(csvContent);
			let link = document.createElement("a");
			let filename = state.user?.user?.name + '_' + state.selectedCollaboration[55].value + '_' + state.selectedCampaign[13].value + ".csv"; // Client Name_Collaboration Name_Campaign Name
			link.setAttribute("href", encodedUri);
			link.setAttribute("download", filename);
			document.body.appendChild(link); // Required for FF
			link.click();
		},
		sumUpMetric(fid) {
			let sum = 0;

			this.contents.forEach(content => {
				let value = content[fid]?.value;
				if (typeof value === 'number') {
					sum += value;
				}
			});
			return sum;
		},
		filterContentByMonth() {
			if (this.selectedMonth) {
				let contents = this.filterContents();
				this.contents = contents.filter(content => {
					console.log(new Date(content[14].value).getMonth() + 1, this.selectedMonth);
					return new Date(content[14].value).getMonth() + 1 == this.selectedMonth;
				});
			} else {
				this.contents = this.filterContents();
			}
		
		},
		getMonth(value) {
			// get only the month value from new Date(content[14].value)
			let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			return months[new Date(value).getMonth()];
		},
		splitTags(themes, identities) {
			this.creatorThemes = themes.split(',');
			this.creatorIdentities = identities.split(',');
		},
		async revertToPending(creatorApprovalRecord) {
			let response = await state.api.call('sourcing', 'updateFlag', {sourceRID: creatorApprovalRecord[3].value, flag: "", notes: "Reverted to pending."})
			console.log(response);
		}
	},
	watch: {
		selectedFilter() {
			this.creatorApprovals = this.filterCreatorApprovals();
			this.contentApprovals = this.filterContentApprovals();
			this.contents = this.filterContents();
		}
	},
	computed: {
		
	},
	components: {
		CreatorApproval
	}
}
</script>

<style scoped>
.status {
	width: 109px;
	height: 24px;
	text-transform: uppercase;
	border-radius: 5px;

	font-size: 14px;

	padding: 8px;

	margin-left: 6px;
}

.status-green {
	background-color: #8ABD57;
}
.status-yellow {
	background-color: #F9D54A;
}
.status-red{
	background-color: #F16A6A;
}
.download-btn {
  width: 140px;
  height: 40px;
  border-radius: 100px;
  border: 2px solid #102A18;

  background-color: white;

  font-size: 14px;
  font-weight: 600;
  color: #005FCC;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 12px;
}

.link-wrapper {
    word-break: break-word;
}

.link-wrapper a {
    word-break: break-all;
}

.post-data-row {
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-top: 24px;
}
.metric-title {
	font-size: 13px;
	text-align: start;
}

.metric-value {
	font-size: 13px;
	font-weight: 700;
	text-align: start;

	min-height: 15px;
}

.metric-link {
	font-size: 14px;
	color: #005FCC;
	text-decoration: underline;
	cursor: pointer;
	font-weight: 700;
	text-align: left;
}

.metrics-divider {
	width: 2px;
	height: 100%;
	background-color: #BFBDC1;
	margin: 0 10px;
}
.metric {
	display: flex;
}
.metric img {
	/* display: block; */
	margin: 6px;

	width: 24px;
	height: 24px;
}
.metrics-row .metric {
	flex: 1;
	/* background: blue; */
	height: 50px;
}
.metrics-row {
	display: flex;
	justify-content: space-between;

	border-bottom: 2px solid #BFBDC1;

	padding-bottom: 20px;
}

.content-details {
	flex: 1;
	/* background: green; */
	/* border-left: 2px solid #BFBDC1; */
}
.content-item {
	display: flex;

	height: 100%;
}

.content-item:not(:first-child):not(:last-child) {
  margin: 25px 0;
}
.content-list {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	/* background: red; */
	flex: 1;

}
.creator-metrics-container {
	padding: 24px;
	width: 100%;
	max-width: 100%;
	display: flex;
	/* min-height: 200px; */

	background: white;
	border-radius: 24px;
	border: 2px solid black;

	box-sizing: border-box; 

	text-align: unset;

	margin-bottom: 24px;

}

.creator-details {
	width: 12%;
	/* background: red; */
}

.tags-row {
	display: flex;
}

.tags-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-left: 10px;
}

.tags-list {
	margin-top: 4px;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 12px;
}

.tag-item {
	margin: 6px;
	margin-top:-2px;
	margin-left: 0;
	margin-right: 6px;
	font-size: 10pt; 
	/* color:white; */
	padding:4px 8px;
	border-radius:11px;
	/* width:100%; */
	text-align:center;
	border: 1px solid black;
}

/* .tag-item:first-child {
    margin-left: 0;
} */


/* ********************************************************************/


.content-approval-card {
	max-width: 100%;
	height: 222px;
	border: 2px solid black;
	border-radius: 24px;

	display: flex;

	padding: 24px;
	margin-bottom: 24px;

	background-color: white;

}

.creator-approval-card {
	max-width: 100%;
	height: 222px;
	border: 2px solid black;
	border-radius: 24px;

	display: flex;
	flex-direction: column;

	padding: 24px;
	margin-bottom: 24px;

	background-color: white;
}

.content-approval-card {
	height: auto;
	min-height: 222px;
}

.creator-approval-card .left,
.content-approval-card .left, .creator-details {
	display: flex;
	flex-direction: column;

	height: 100%;

	text-align: left;

	margin-right: 36px;
	min-width: 12vw;
}

.creator-approval-card .creator-info {
	display: flex;
}

.creator-approval-card .left .img-wrapper,
.content-approval-card .left .img-wrapper, .creator-details .img-wrapper {
	width: 88px;
	height: 88px;
	margin-bottom: 14px;

	border-radius: 100px;
	overflow: hidden;
}

.creator-details .img-wrapper {
	height: initial;
	border-radius: 10px;
}

.creator-approval-card .left img,
.content-approval-card .left img, .creator-details .img-wrapper img {
	width: 100%;
	height: 100%;
}

.creator-approval-card .right {
	height: 100%;
	width: 100%;
	flex: 1;

	display: flex;
}

.content-approval-card .right {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	
}

.creator-approval-card .right .stats-row {
	height: 50%;
	display: flex;
	align-items: center;

	text-align: left;
}

.creator-approval-card .right .creator-column {
	display: flex;
	flex-direction: column;
	height: 100%;
	flex: 1;
	min-height: 186px;
}

.creator-approval-card .right .social-follower-section {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.content-approval-card .right .details-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;

	text-align: left;
}

.content-approval-card .right .details-row .column {
	display: flex;
	flex-direction: column;

	width: 31%;
	height: 100%;



}

.creator-approval-card .right .stats-row .social-icon-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 64px;
	height: 64px;
}

.creator-approval-card .right .column {
	display: flex;
	flex-direction: column;
	text-align: left;
	/* margin-right: 20px; */
}

.creator-approval-card .right .buttons-row,
.content-approval-card .right .buttons-row {
	display: flex;
	align-items: center;
}

.creator-approval-card .buttons-row {
	margin-top: 12px;
}

.content-approval-card .right .buttons-row {
	margin-top: 24px;
}

.creator-approval-card .buttons-row button,
.content-approval-card .right .buttons-row button,
.content-details .buttons-row button {
	height: 40px;
	min-width: 140px;
	padding: 0 20px;
	border-radius: 100px;
	border: 2px solid #102A18;
	font-size: 14px;
	font-weight: 600;

	margin-right: 12px;
}

.creator-approval-card {
	height: auto;
}

.btn-green {
	background-color: #8ABD57;
}

.btn-yellow {
	background-color: #F9D54A;
}

.btn-red {
	background-color: #F16A6A;
}

.btn-white {
	background-color: white;
	color: #005FCC;
}

.label {
	font-size: 13px;
	margin-bottom: 5px;
}

.value {
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 17px;
}

.content {
	padding: 24px 24px 0;

	overflow-y: auto;

	height: 100%;
}

#info-bar {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	min-height: 72px;
	max-width: 100%;
	padding: 0 24px;
	background-color: #BFBDC1;

	z-index: 5;
}

#info-bar .divider {
	width: 1px;
	height: 60%;
	background-color: #102A18;
	margin: 0 20px;
}

.info-bar-group {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin: 0 20px;
	height: 100%;
}

.info-bar-group .title {
	font-size: 13px;
	margin-bottom: 6px;

}

.info-bar-group .text {
	font-size: 17px;
	font-weight: 700;

	min-height: 20px;
}

#nav-btn-container {
	cursor: pointer;
	min-height: 52px;
}</style>