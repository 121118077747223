import { createRouter, createWebHistory } from 'vue-router';
import Campaigns from '../views/Campaigns.vue';
import Campaign from '../views/Campaign.vue';
// import Invoices from '../views/Invoices.vue';
import Contract from '../views/Contract.vue';

import Login from '../views/Login.vue';
import ChangePassword from '../views/ChangePassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import SignUp from '../views/SignUp.vue';


import { state } from '../store/store.js';

const routes = [
	{
		path: '/',
		name: 'Login',
		component: Login,
		alias: '/login'
	},
	{
		path: '/change-password',
		name: 'ChangePassword',
		component: ChangePassword,
	},
	{
		path: '/reset-password/:email/:token',
		name: 'ResetPassword',
		component: ResetPassword,
	},
	{
		path: '/forgot-password',
		name: 'ForgotPassword',
		component: ForgotPassword,
	},
	// {
	// 	path: '/login',
	// 	name: 'Login',
	// 	component: Login,
	// },
	{
		path: '/campaigns',
		name: 'Campaigns',
		component: Campaigns,
	},
	{
		path: '/campaigns/:id',
		name: 'Campaign',
		component: Campaign,
	},
	{
		path: '/invoices',
		name: 'Invoices',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/Invoices.vue'),
	},
	{
		path: '/contract',
		name: 'Contract',
		component: Contract,
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

// If a user attempts to access a route that is not in the validRoutes array, redirect them to the Campaigns page.
const validRoutes = [
	'Campaigns',
	'Campaign',
	'Invoices',
	'Contract',
	'ResetPassword',
	'ChangePassword',
	'ForgotPassword',
	// hamburgur menu ONLY routes
	// Needs to be navigation guarded once auth is implemented
	'SignUp',
	'Login',
	'/'
];

const publicRoutes = [
	'SignUp',
	'Login',
	'ResetPassword',
	'ForgotPassword',
	'/'
];

router.beforeEach(async (to, from) => {
	
	if (state.user) {
		state.isUserLoggedIn = true;
	} else {
		state.isUserLoggedIn = false;
	}

	if (to.name === 'Campaign' && !state.collaborations.length) {
		return '/campaigns/'
	}
	
	if (state.isUserLoggedIn) {
		if (!validRoutes.includes(to.name)) {
			return '/campaigns';
		}
	} else {
		if (!publicRoutes.includes(to.name)) {
			return '/login';
		}
	}
});

export default router;
