<script setup>
import { state } from '@/store/store.js';
</script>

<template>
    <div id="container">
        <img alt="logo" src="@/assets/logo.png">

        <h1 id="title-text">Client's Portal</h1>

        <div id="form">
            <div class="input-group">
                <label for="email">Email</label>
                <input v-model="email" type="text" placeholder="Enter your email" id="email">
            </div>
            <div class="input-group" v-if="displayPassword == false">
                <label for="password-hidden">Password</label>
                <input v-model="password" type="password" placeholder="Password" id="password-hidden">
                <!-- <i @click="toggleDisplayPassword($event)" class="bi bi-eye"></i> -->
            </div>
            <!-- <div class="input-group" v-else>
                <label for="password-displayed">Password</label>
                <input v-model="password" type="text" placeholder="" id="password-displayed">
                <i @click="toggleDisplayPassword($event)" class="bi bi-eye-slash"></i>
            </div> -->

            

            <div v-if="errorMessage" id="error-message-container">
                <img alt="alert-triangle" src="@/assets/alert-triangle.svg">
                <span id="error-message">
                    {{ errorMessage }}
                </span>
            </div>

            <button class="login" :class="{'login-enabled' : isLoginEnabled()}" :disabled="!isLoginEnabled()" @click="authenticate()" >
                <span style="margin-right: 6px;">Sign In</span>
                <img src="@/assets/arrow-right.svg" alt="login">
            </button>

            <div id="forgot-password-container">
                <!-- <a href="#">Forgot Password?</a> -->
                <span @click="$router.push('/forgot-password');" style="color: blue; text-decoration: underline; cursor: pointer;">Forgot Password?</span>
            </div>

            <!-- <button id="signup" @click="$router.push('/sign-up')">
                <span>Sign Up</span>
                <img src="@/assets/arrow-right.svg" alt="arrow right">
            </button> -->
        </div>
    </div>
</template>
  
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: 'Login',
    components: {
        // HelloWorld
    },
    data() {
        return {
            email: "",
            password: "",
            displayPassword: false,
            errorMessage: ""
        }
    },
    async mounted() {
        if (state.user) {
            this.$router.push({ name: "Campaigns" });
        } else {
            // Get email from router params
            if (this.$route.params.email) {
                this.email = this.$route.params.email;
            }
        }
    },
    methods: {
        async authenticate() {
            try {
                state.user = await state.api.call("user", "authenticate", {email: this.email, password: this.password});
                this.$router.push({ name: "Campaigns" });
                state.refreshData();

            } catch (error) {
                console.error(error);
                this.errorMessage = error.message;
            }
        },
        toggleDisplayPassword(event) {
            console.log(event)
            event.stopPropagation();
            event.preventDefault();

            this.displayPassword = !this.displayPassword;
        },
        isLoginEnabled() {
            return this.email && this.password;
        }
    }
}
</script>

<style scoped>
#error-message-container {
    margin-top: 12px;

    display: flex;

}

#error-message-container img {
    margin-right: 12px;
}

#error-message {
    color: #C56327;
    text-align: left;
}


#container {
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    /* width: 100vw; */
    height: 100vh;
    /* justify-content: center; */
    align-items: center;
}

#title-text {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    margin: 36px 0;
}

.input-group {
    /* display: flex;
    flex-direction: column; */
    width: 100%;
    /* height: 72px; */

    margin-bottom: 20px;
}

.input-group label {
    height: 28px;

    font-size: 13px;
    /* font-weight: 600; */
    line-height: 16px;

    display: flex;
    align-items: center;

    user-select: none;
    /* align-self: flex-start; */
    /* justify-content: center; */

}

.input-group input {
    /* height: 56px; */
    padding: 10px 16px;
    height: 32px;
    width: calc(100% - 36px);

    border-radius: 22px;
    border: 2px solid #102A18;
}

.input-group img {
    position: absolute;
    /* top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
    width: 24px;
    height: 24px;
}

input:focus::placeholder {
    color: transparent;
}

input::placeholder {
    user-select: none;
}

#form i {
    margin-left: -30px;
    cursor: pointer;
    color: var(--btn-blue-dark);
}

/* #password-hidden {
    margin-left: -14px;
}

#password-displayed {
    margin-left: -14px;
} */


#form {
    width: 360px;
    margin-top: 3vh;
}

#forgot-password-container {
    display: flex;
    justify-content: center;
    margin-top: 2vh;

    font-size: 17px;
    font-weight: 700;

}

/* make it so that if the a tag is visited, it's still blue */


#forgot-password-container a:link,
#forgot-password-container a:visited {
    color: blue;
}

#forgot-password-container a:hover {
    text-decoration: underline;
}


.login {
    display: block;
    /* margin-top: 24px; */
    width: 100%;
    height: 52px;
    margin-top: 36px;

    border-radius: 100px;

    color: var(--text-default-color);
    background-color: #BFBDC1;
    border: 2px solid white;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    cursor: not-allowed;
    /* pointer-events: none; */

    display: flex;
    justify-content: center;
    align-items: center;
}

.login-enabled {
    background-color: var(--button-yellow);
    border-color: black;
    cursor: pointer;
}

#signup {
    border: 1px solid #CFD8DC;
    color: var(--btn-blue-dark);
    background-color: white;
}

#login span,
#signup span {
    margin-right: 6px;
}

button:focus {
    outline: 1px solid black !important;
}

#email::placeholder {
    /* color: #CFD8DC; */
}
</style>
  