import { reactive } from 'vue';
import PortalAPI from '../api.js';

// Reactive object for data that will be written to DB
export const store = reactive({
	count: 0,
	increment() {
		this.count++;
	}
});

// Reactive object to control current state of Vue app
export const state = reactive({
	api: null,
	isAPIProcessing: true,
	toMoney(value) {
		// USD format with commas
		return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
	},
	toFormattedInteger(value) {
		if (value === null || value === undefined) return '';
		// format a numerical string value into a string with commas
		return new Intl.NumberFormat().format(value);
	},
	unixToDate(unix) {
		if (!unix) return "";

		const date = new Date(unix);

		const options = {
			day: 'numeric',
			month: 'short',
			year: 'numeric',
			hour: 'numeric',
			minute: '2-digit',
			hour12: true,
			comma: false
		};
		const formattedString = date.toLocaleString('en-US', options);
		return formattedString.replace(/,/g, '');
	},

	// Fetches all data from the server and UPDATES the data in the state, not replace
	async refreshData() {
		await this.refreshDataCampaigns();
		await this.refreshDataCollaborations();
		await this.refreshDataSourcings();
		await this.refreshDataDraftEntries();
		await this.refreshDataInvoices();
		await this.refreshDataContracts();
		await this.refreshDataContents();
	},
	async refreshDataCampaigns() {
		let campaigns = (await this.api.call('campaign', 'listAll', {})).campaigns;

		// Update campaigns
		campaigns.forEach((campaign, index) => {
			if (state.campaigns[index]) {
				Object.assign(state.campaigns[index], campaign);
			} else {
				state.campaigns.push(campaign);
			}
		});

		// Remove campaigns that no longer exist
		if (campaigns.length < state.campaigns.length) {
			state.campaigns.splice(campaigns.length, state.campaigns.length - campaigns.length);
		}
	},
	async refreshDataCollaborations() {
		let collaborations = (await this.api.call('collaboration', 'listAll', {})).collaborations;

		// Update collaborations
		collaborations.forEach((collaboration, index) => {
			if (state.collaborations[index]) {
				Object.assign(state.collaborations[index], collaboration);
			} else {
				state.collaborations.push(collaboration);
			}
		});

		// Remove collaborations that no longer exist
		if (collaborations.length < state.collaborations.length) {
			state.collaborations.splice(collaborations.length, state.collaborations.length - collaborations.length);
		}
	},
	async refreshDataSourcings() {
		let sourcings = (await this.api.call('sourcing', 'listAll', {})).sourcings;

		// Update sourcings
		sourcings.forEach((sourcing, index) => {
			if (state.sourcings[index]) {
				Object.assign(state.sourcings[index], sourcing);
			} else {
				state.sourcings.push(sourcing);
			}
		});

		// Remove sourcings that no longer exist
		if (sourcings.length < state.sourcings.length) {
			state.sourcings.splice(sourcings.length, state.sourcings.length - sourcings.length);
		}
	},
	async refreshDataDraftEntries() {
		let draftEntries = (await this.api.call('draft_entries', 'listAll', {})).draftEntries;

		// Update draft entries
		draftEntries.forEach((draftEntry, index) => {
			if (state.draftEntries[index]) {
				Object.assign(state.draftEntries[index], draftEntry);
			} else {
				state.draftEntries.push(draftEntry);
			}
		});

		// Remove draft entries that no longer exist
		if (draftEntries.length < state.draftEntries.length) {
			state.draftEntries.splice(draftEntries.length, state.draftEntries.length - draftEntries.length);
		}
	},
	async refreshDataInvoices() {
		let invoices = (await this.api.call('invoices', 'listAll', {})).invoices;

		// Update invoices
		invoices.forEach((invoice, index) => {
			if (state.invoices[index]) {
				Object.assign(state.invoices[index], invoice);
			} else {
				state.invoices.push(invoice);
			}
		});

		// Remove invoices that no longer exist
		if (invoices.length < state.invoices.length) {
			state.invoices.splice(invoices.length, state.invoices.length - invoices.length);
		}
	},
	async refreshDataContracts() {
		let contracts = (await state.api.call('documents', 'listAll', {})).documents;

		// Update contracts
		contracts.forEach((contract, index) => {
			if (state.contracts[index]) {
				Object.assign(state.contracts[index], contract);
			} else {
				state.contracts.push(contract);
			}
		});

		// Remove contracts that no longer exist
		if (contracts.length < state.contracts.length) {
			state.contracts.splice(contracts.length, state.contracts.length - contracts.length);
		}
	},
	async refreshDataContents() {
		let contents = (await state.api.call('table_content', 'listAll', {})).content;

		// Update contents
		contents.forEach((content, index) => {
			if (state.contents[index]) {
				Object.assign(state.contents[index], content);
			} else {
				state.contents.push(content);
			}
		});

		// Remove contents that no longer exist
		if (contents.length < state.contents.length) {
			state.contents.splice(contents.length, state.contents.length - contents.length);
		}
	},
	user: null,
	selectedCollaboration: null,
	selectedCampaign: null,
	selectedCampaignFilter: 'active',
	campaigns: [],
	collaborations: [],
	sourcings: [],
	draftEntries: [],
	invoices: [],
	contracts: [],
	contents: [],
	availableUsers: [],
	availableClients: [],
	isUserLoggedIn: false,
	hamburgerMenu: {
		display: false,
		toggle() {
			this.display = !this.display;
		},
	},
	creatorApprovalMenu: {
		display: false,
		toggle() {
			this.display = !this.display;
		}
	},
	contentApprovalMenu: {
		display: false,
		toggle() {
			this.display = !this.display;
		}
	}
});

state.api = new PortalAPI(state);
