<script setup>
    import { state } from '../store/store.js';
</script>

<template>
	<div class="container">
		<div
			class="nav-btn close-icon"
			@click="closeMenu()"
			style="margin-top: 16px; cursor: pointer;"
		>	
			<img alt="x icon" src="@/assets/close-icon.svg">
		</div>

		<div class="divider"></div>

		<div
			class="hamburger-section"
			@click="closeMenu()"
			style="margin-top: 16px; font-weight: 400; padding: 13px 24px; text-align: left;"
		>
			{{state.user.user.email}}
		</div>

		<div v-if="state.user.user.role.toLowerCase() === 'vocal media' || state.user.user.role.toLowerCase() === 'vocal media restricted'">
			View As:
			<select class="hamburger-section" :value="state.user.user.clientRID" style="padding: 13px 24px; text-align: left; width: 85%;" @change="(e) => viewDataAsSpecificClient(e.target.value)">
				<option v-for="client in state.availableClients" :key="client[3].value" :value="client[3].value">{{client[13].value}}</option>
			</select>
		</div>
		
		<router-link
			to="/change-password"
			class="nav-btn hamburger-section"
			@click="closeMenu()"
		>
			Change Password
		</router-link>
		<router-link
			to="/login"
			class="nav-btn hamburger-section"
			@click="signout()"
		>
			Sign Out
		</router-link>


		<div id="copyright-text">
			<span>©️ 2023 Vocal Media</span>
		</div>
		<!-- Copyright info -->
	</div>
	<div id="curtain" @click="closeMenu()"></div>
</template>

<script>
export default {
	name: 'HamburgerMenu',
	props: {
		msg: String,
	},
	data() {
		return {
		}
	},
	mounted: function() {
		if (state.user.user.role.toLowerCase() === 'vocal media' || state.user.user.role.toLowerCase() === 'vocal media restricted') {
			this.refreshAvailableClients();
		}
	},
	methods: {
		closeMenu() {
			state.hamburgerMenu.toggle();
		},
		async signout() {
			// this.closeMenu();

			state.user = await state.api.call("user", "logout", {});
			location.reload();
		},
		async refreshAvailableClients() {
			state.availableClients = (await state.api.call("user", "getAllClients", {})).clients;
			state.availableClients.sort((a, b) => a[13].value.localeCompare(b[13].value));
		},
		async viewDataAsSpecificClient(clientRID) {
			// this.closeMenu();

			state.user = await state.api.call("user", "viewDataAsSpecificClient", {clientRID});
			location.reload();
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.container {
	background: white;

    display: flex;
    flex-direction: column;
	/* align-items: flex-end; */

    position: absolute;
	z-index: 10;

    right: 0;
    top: 0;
    height: 100vh;
	width: 250px; /*  234px to match figma literal */

	/* padding: 0 24px; */

}

.container a, .container div {
	/* text-align: right; */

	margin: 10px 0px;
}

.hamburger-section {
	/* padding-y: 24px; */
	padding: 13px 24px;
}

.close-icon {
	align-self: flex-end;
}
.divider {
	width: 100%;
	height: 2px;
	background: #BFBDC1;;
}

#copyright-text {
	font-size: 14px;
	font-weight: 400;
	color: #757575;

	width: 100%;
	margin-top: auto;
}

#curtain {
	position: absolute;
	z-index: 5;

	background: rgba(0,0,0,0.5);

	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

}
</style>
